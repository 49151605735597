var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('h5',[_vm._v(_vm._s(_vm.$t("bestOfCourses")))]),_c('div',{staticClass:"row mt-4"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center col-12"},[_c('PageLoading')],1):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-md-6 col-lg-2"},[(!_vm.isLoading)?_c('RadarChartCourseStats',{attrs:{"title":item.courseTitle,"series":[
          item.averageStudyTime,
          item.averageQuizScore,
          item.studentCount,
          item.certificateCount,
          item.averageCommentScore,
        ]}}):_vm._e()],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
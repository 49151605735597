<template>
  <div>
    <ReportCenterTitle :title="$t('courseInsights')" :count="count" />
    <hr />
    <TopCourses :limit="6" />
    <div class="row mt-4">
      <div class="col-12 col-lg-6">
        <h5>{{ $t("mostProfitableCourses") }}</h5>
        <BestPurchasedEnrollments :limit="15" />
      </div>
      <div class="col-12 col-lg-6">
        <h5>{{ $t("mostPopularCourses") }}</h5>
        <BestEnrollments :limit="15" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-lg-3">
        <h5 class="mb-3">{{ $t("mostPopularCourses") }}</h5>
        <hr />
        <BestCourseStudentList :limit="5" />
      </div>
      <div class="col-12 col-lg-3">
        <h5 class="mb-3">{{ $t("mostProfitableCourses") }}</h5>
        <hr />
        <BestCoursePurchasedList :limit="5" />
      </div>
      <div class="col-12 col-lg-3">
        <h5 class="mb-3">{{ $t("leastPopularCourses") }}</h5>
        <hr />
        <WorstCourseStudentList :limit="5" />
      </div>
      <div class="col-12 col-lg-3">
        <h5 class="mb-3">{{ $t("leastProfitableCourses") }}</h5>
        <hr />
        <WorstCoursePurchasedList :limit="5" />
      </div>
    </div>
  </div>
</template>

<script>
import TopCourses from "./TopCourses.vue";
import BestPurchasedEnrollments from "./BestPurchasedEnrollments.vue";
import BestEnrollments from "./BestEnrollments.vue";
import BestCourseStudentList from "./BestCourseStudentList.vue";
import BestCoursePurchasedList from "./BestCoursePurchasedList.vue";
import WorstCoursePurchasedList from "./WorstCoursePurchasedList.vue";
import WorstCourseStudentList from "./WorstCourseStudentList.vue";
import ReportCenterTitle from "../Title.vue";

export default {
  components: {
    TopCourses,
    ReportCenterTitle,
    BestPurchasedEnrollments,
    BestCourseStudentList,
    BestEnrollments,
    WorstCourseStudentList,
    WorstCoursePurchasedList,
    BestCoursePurchasedList,
  },
};
</script>

<style></style>

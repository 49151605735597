<template>
  <div>
    <apexchart
      type="radialBar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <div class="text-center mt-2">
      <small
        ><i> {{ title }}</i>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    series: {
      default: () => [],
    },
    title: {
      default: "",
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              margin: 8,
              fontSize: "10px",
              formatter: function (seriesName, opts) {
                return (
                  seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                );
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        labels: [
          this.$t("averageStudyTime"),
          this.$t("averageQuizScore"),
          this.$t("studentCount"),
          this.$t("certificateCount"),
          this.$t("averageCommentScore"),
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style></style>

<template>
  <div>
    <CourseInsights />
  </div>
</template>

<script>
import CourseInsights from "../components/ReportCenter/CourseInsights";
export default {
  components: {
    CourseInsights,
  },
};
</script>

<style></style>
